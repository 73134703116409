import React, { useEffect, useState } from "react";
import {
  TextField as MuiTextField,
  TextFieldProps,
  Autocomplete,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface Item {
  id: number;
  name?: string;
  description?: string;
}

export type OlogaAutocompleteProps = {
  name: string;
  label: string;
  options: Item[];
  initValue?: string;
  handleChange?: (value: any) => void;
} & TextFieldProps;

const OlogaAutocomplete: React.FC<OlogaAutocompleteProps> = ({
  name,
  label,
  options,
  initValue = "",
  handleChange,
  ...otherProps
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [field, meta] = useField(name);
  const [inputValue, setInputValue] = useState(initValue);

  const configAutocomplete: TextFieldProps = {
    ...field,
    ...otherProps,
    name: name,
    label: label,
    variant: "outlined",
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configAutocomplete.error = true;
    configAutocomplete.helperText = meta.error;
  }

  return (
    <Autocomplete
      options={options}
      inputValue={inputValue || ""}
      getOptionLabel={(option: any) =>
        option.name ? option.name : option.description
      }
      onChange={(e, value: any) => {
        setFieldValue(name, value !== null ? value.id : "", true);
        if (value) {
          setInputValue(value.name ? value.name : value.description);
        } else {
          setInputValue("");
        }

        handleChange && handleChange(value);
      }}
      onInputChange={(e, value: any) => {
        if (e && e.type === "change") {
          setInputValue(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...configAutocomplete}
          my={2}
          // value={values[name]?.id}
        />
      )}
    />
  );
};

export default OlogaAutocomplete;
