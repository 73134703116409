import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Card as MuiCard, Grid } from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";

const Card = styled(MuiCard)(spacing);

function VenueList() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: 1,
      editable: false,
    },
    {
      field: "cityName",
      headerName: "City",
      flex: 1,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/events/edit-venue", {
              state: {
                venue: params.row,
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Venues"
        items={[{ label: "Events", url: "/events" }]}
        buttonLabel="New Venue"
        onClick={() => {
          navigate("/events/add-venue");
        }}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/venues/q"} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VenueList;
