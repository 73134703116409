import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import styled from "@emotion/styled";

import {
  Button,
  CardContent,
  Grid,
  Input,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import Item from "../../components/combosx/Item";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";
import { Formik } from "formik";

interface ExcelData {
  columnName1: string;
  columnName2: number;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function BankStatementUpload() {
  const [event, setEvent] = useState({
    id: 0,
    description: "",
    type: "",
    name: " ",
  });
  const [events, setEvents] = useState([{ id: 0, name: "" }]);
  const [eventId, setEventId] = useState(0);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<ExcelData[] | null>(null);
  const [loading, setLoading] = useState(false);

  const [fixerStats, setfixerStats] = useState({
    totalTxOnFile: 0,
    totalCreated: 0,
    totalUpdated: 0,
    totalFailed: 0,
  });

  const handleEventChange = (event: Item) => {
    setEvent(event as any);
    setEventId(Number(event.id));
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          // Convert array buffer to string
          const binaryString = event.target.result as ArrayBuffer;
          const workbook = read(new Uint8Array(binaryString));
          const sheetName = workbook.SheetNames[0];
          const sheetData = utils.sheet_to_json(
            workbook.Sheets[sheetName]
          ) as ExcelData[];
          setData(sheetData);
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.warning("Select a file before uploading it.");
    }

    setLoading(true);
    const editedData = data?.map((r: any) => {
      return {
        transactionTime: r["Transaction Time"]
          ? r["Transaction Time"].includes(".")
            ? r["Transaction Time"].split(".")[0]
            : r["Transaction Time"].split("+")[0]
          : r["Transaction Time"],
        state: r["State"],
        terminalID: r["Terminal ID"],
        transactionAmount: r["Transaction Amount"],
        authID: r["Auth ID"],
        reference: r["Reference"],
        settlementDate: r["Settlement Date"],
      };
    });

    try {
      const response = await axios.post(`banktx/import/${eventId}`, editedData);
      setShow(false);
      setfixerStats(response.data);
      setShow(true);
      setLoading(false);
      toast.success("File uploaded sucessfully");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const handleTxFixer = async () => {
    try {
      const response = await axios.post(`banktx/fix/${eventId}`);

      setShow(false);
      setfixerStats(response.data);
      setShow(true);

      toast.success("Process executed sucessfully");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  useEffect(() => {
    retriveEvents();
  }, []);

  function EmptyCard() {
    return <Card mb={6}></Card>;
  }

  const retriveEvents = async () => {
    const response = await axios.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  return (
    <React.Fragment>
      <OlogaPageHeader title="Bank Statement Upload" />

      <Card mb={12}>
        <OlogaLoading open={loading} />
        <CardContent>
          <Formik initialValues={event} onSubmit={(value) => {}}>
            {({
              handleSubmit,
              values,
              status,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={3}>
                    <OlogaAutocomplete
                      name="event"
                      options={events}
                      label="Event"
                      initValue={event.name}
                      handleChange={handleEventChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={6}>
                  <div>
                    <Input
                      type="file"
                      inputProps={{ accept: ".xlsx, .xls" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <Grid
                    container
                    marginTop={5}
                    spacing={3}
                    mt={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Grid item marginRight={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleFileUpload()}
                      >
                        Upload
                      </Button>{" "}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider my={6} />

                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card mb={6}>
                      <Paper>
                        {show ? (
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">
                                    Total Transactions on File
                                  </TableCell>
                                  <TableCell align="left">
                                    Total Created Transactions
                                  </TableCell>
                                  <TableCell align="left">
                                    Total Updated Transactions
                                  </TableCell>
                                  <TableCell align="left">
                                    Tolal Upload Failed Transactions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {fixerStats.totalTxOnFile}
                                  </TableCell>
                                  <TableCell align="left">
                                    {fixerStats.totalCreated}
                                  </TableCell>
                                  <TableCell align="left">
                                    {fixerStats.totalUpdated}
                                  </TableCell>
                                  <TableCell align="left">
                                    {fixerStats.totalFailed}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <EmptyCard />
                        )}{" "}
                      </Paper>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default BankStatementUpload;
