import { useEffect } from "react";
import { useState } from "react";
import http from "../utils/axios";

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get("/locations/countries/names");
        setCountries(result.data);
      } catch (error) {}
    };

    fetchData();
  }, []);
  return { countries };
};

export default useCountries;
