import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CountryTourForm from "../tours/CountryTourForm";
import WorldTourForm from "../tours/WorldTourForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #bb86fc",
  boxShadow: 24,
  p: 4,
};

const btnStyl = {
  marginTop: "14px",
  textAlign: "right",
};

export default function ToursModal(props: any) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onClosemodal();
    setOpen(false);
  };
  const { onClosemodal } = props;
  return (
    <div>
      <Button sx={btnStyl} onClick={handleOpen} variant="outlined">
        {props.btnName}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <hr />
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="World Tours" value="1" />
                  <Tab label="Country Tours" value="2" />
                </TabList>
              </Box>
              <TabPanel value="2">
                <CountryTourForm
                  tour={{
                    id: null,
                    name: "",
                    worldTourId: "",
                    countryId: "",
                    startDate: "",
                    endDate: "",
                    fromModel: true,
                  }}
                  closemodal={handleClose}
                />
              </TabPanel>
              <TabPanel value="1">
                <WorldTourForm
                  tour={{
                    id: null,
                    name: "",
                    artist: "",
                    startDate: "",
                    endDate: "",
                    description: "",
                    fromModel: true,
                  }}
                  closemodal={handleClose}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
