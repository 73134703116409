import styled from "@emotion/styled";

import { Card as MuiCard, Grid } from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

function StationList() {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
    },
    {
      field: "eventName",
      headerName: "Event",
      flex: 1,
      editable: false,
    },
    {
      field: "venueName",
      headerName: "Venue",
      flex: 1,
      editable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      editable: false,
    },
    {
      field: "geoLocation",
      headerName: "Geo Location",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/events/edit-station", {
              state: {
                station: params.row,
                // vendorId: params.row.vendorId,
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];
  return (
    <>
      <OlogaPageHeader
        title="Stations"
        items={[{ label: "Events", url: "/events" }]}
        buttonLabel="New Station"
        onClick={() => {
          navigate("/events/add-station");
        }}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={"/stations/q"} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default StationList;
