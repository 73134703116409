import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import axios from "../../utils/axios";
import OlogaStats from "../../components/misc/OlogaStats";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const paperStyle = {
  position: "absolute",
  maxWidth: "70%",
  backgroundColor: "background.paper",
  border: "2px solid #bb86fc",
  padding: 4,
  overflow: "hidden",
};

type StockMetadataType = {
  eventProductId: string;
  vendorId: string;
  eventId: string;
  eventName: string;
  productName: string;
  imageUri: string;
  openingStock: number;
  finalOpeningStock: number;
  closingStock: number;
  closingStockManual: number;
  difference: number;
};

const StockDetailModal: React.FC<{
  open: boolean;
  onClose: any;
  stockMetadata: StockMetadataType;
}> = ({ open, onClose, stockMetadata }) => {
  const handleClose = () => {
    setEventProducts([]);
    onClose();
  };
  const [slide, setSlide] = React.useState(false);
  const [eventProducts, setEventProducts] = useState([]);
  const [warehouseOpeningStock, setWarehouseOpeningStock] = useState(0);
  const [totalClosingStockManual, setTotalClosingStockManual] = useState(0);
  const [totalClosingStock, setTotalClosingStock] = useState(0);

  useEffect(() => {
    open && getDetailedStockList(stockMetadata);
  }, [open, stockMetadata]);

  const getDetailedStockList = async (stockMetadata: StockMetadataType) => {
    const response = await axios.get(
      `stock/recon/event/${stockMetadata.eventId}/vendor/${stockMetadata.vendorId}/product/${stockMetadata.eventProductId}`
    );

    if (response && response.data) {
      let closingStockManualSum = 0;
      let closingStockSum = 0;

      const eventProducts = response.data.map((r: any) => {
        if (r.stationName === "WAREHOUSE") {
          setWarehouseOpeningStock(r.openingStock);
        }
        closingStockManualSum += r.closingStockManual;
        closingStockSum += r.closingStock;

        return {
          eventProductId: r.eventProductId,
          productDescription: r.productDescription,
          stationName: r.stationName,
          stationId: r.stationId,
          imageUri: r.imageUri,
          productSizeDescription: r.productSizeDescription,
          productBrand: r.productBrand,
          productSizeId: r.productSizeId,
          openingStock: r.openingStock,
          transferIn: r.transferIn,
          transferOut: r.transferOut,
          finalOpeningStock: r.finalOpeningStock,
          closingStockManual: r.closingStockManual,
          closingStock: r.closingStock,
          quantitySold: r.quantitySold,
          difference: r.closingStockManual - r.closingStock,
        };
      });

      setEventProducts(eventProducts);
      setTotalClosingStockManual(closingStockManualSum);
      setTotalClosingStock(closingStockSum);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} sx={modalStyle}>
      <Box sx={paperStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>
            {/* <Typography variant="h4" gutterBottom>
              Stock Details
            </Typography> */}
            <Typography variant="h3">
              {stockMetadata.eventProductId} - {stockMetadata.productName}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {stockMetadata.eventName}
            </Typography>
          </Grid>

          <Grid container spacing={6} mt={2}>
            <Grid item xs={12} lg={2}>
              <img
                src={stockMetadata.imageUri}
                alt="Product"
                style={{ borderRadius: "8px", width: "90%" }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats title="Opening Stock" value={warehouseOpeningStock} />
            </Grid>
            {/* <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Final Opening"
                value={stockMetadata.finalOpeningStock}
              />
            </Grid> */}
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Closing Manual"
                value={totalClosingStockManual}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats title="Closing Stock" value={totalClosingStock} />
            </Grid>
            <Grid item xs={12} lg={2}>
              <OlogaStats
                title="Difference"
                value={totalClosingStockManual - totalClosingStock}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Divider style={{ margin: "20px 0" }} />
          </Grid> */}

          <Grid item xs={12}>
            {/* Table */}
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Station Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Opening Stock</TableCell>
                    <TableCell>Transfer In</TableCell>
                    <TableCell>Transfer Out</TableCell>
                    <TableCell>Final Opening Stock</TableCell>
                    <TableCell>Manual Closing Stock</TableCell>
                    <TableCell>Closing Stock</TableCell>
                    <TableCell>Quantity Sold</TableCell>
                    <TableCell>Diference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      eventProductId,
                      imageUri,
                      productDescription,
                      stationName,
                      productSizeDescription,
                      openingStock,
                      transferIn,
                      transferOut,
                      finalOpeningStock,
                      closingStockManual,
                      closingStock,
                      quantitySold,
                      difference,
                    }) => (
                      <TableRow
                        key={eventProductId + productDescription + transferIn}
                      >
                        <TableCell>{stationName}</TableCell>
                        <TableCell>{productSizeDescription || "N/A"}</TableCell>
                        <TableCell>{openingStock}</TableCell>
                        <TableCell> {transferIn}</TableCell>
                        <TableCell> {transferOut}</TableCell>
                        <TableCell> {finalOpeningStock}</TableCell>
                        <TableCell>{closingStockManual}</TableCell>
                        <TableCell>{closingStock}</TableCell>
                        <TableCell>{quantitySold}</TableCell>
                        <TableCell>{difference}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default StockDetailModal;
