import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useLookups from "../../hooks/useLookups";
import http from "../../utils/axios";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";

import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import { companySchema } from "../../utils/formValidation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);

interface CompanyFormProps {
  company?: any;
  type: string;
}

function CompanyForm({ company, type }: CompanyFormProps) {
  const navigate = useNavigate();
  const { data: countries } = useLookups("/locations/countries/names");
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const url = type === "Promoter" ? "promoters" : "vendors";

      if (company?.id) {
        await http.put(`/companies/${url}`, values);
      } else {
        await http.post(`/companies/${url}`, values);
      }
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      toast.success(`${type} saved successfully!`);
      navigate(`/events/${url}`);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error(error.message || "Error saving vendor");
    }
  };

  useEffect(() => {
    if (company?.id) {
      getCities(company.countryId);
    }
  }, [company]);

  useEffect(() => {
    if (selectedCountry) {
      getCities(selectedCountry);
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (value: any) => {
    setSelectedCountry(value);
  };

  const getCities = async (countryId: any) => {
    setCities([]);
    if (countryId) {
      try {
        const result = await http.get(
          `/locations/countries/${Number(countryId)}/cities/names`
        );
        setCities(result.data);
      } catch (error: any) {}
    }
  };

  return (
    <Formik
      initialValues={company}
      validationSchema={companySchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, status, errors }) => (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
                Company details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OlogaTextfield name="name" label="Name" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <OlogaSelect
                    name="countryId"
                    options={countries}
                    label="Country"
                    setValue={handleCountryChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaSelect name="cityId" options={cities} label="City" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="phoneNumber" label="Phone number" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="email" label="Email" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OlogaTextfield name="address" label="Address" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OlogaTextfield
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
              <Typography variant="h6" gutterBottom>
                Representative
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <OlogaTextfield name="representativeName" label="Full name" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield
                    name="representativePhoneNumber"
                    label="Phone number"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="representativeEmail" label="Email" />
                </Grid>
              </Grid>
              <OlogaButtonGroup />
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default CompanyForm;
