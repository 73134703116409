import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import WorldTourForm from "./WorldTourForm";

const Card = styled(MuiCard)(spacing);

function CreateWorldTour() {
  return (
    <>
      <OlogaPageHeader
        title="Add World Tour"
        items={[{ label: "World Tours", url: "/events/world-tours" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <WorldTourForm
                tour={{
                  id: null,
                  name: "",
                  artist: "",
                  startDate: "",
                  endDate: "",
                  description: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateWorldTour;
