import { Card, CardContent, Grid } from "@mui/material";

import StationForm from "./StationForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

function CreateVenue() {
  return (
    <>
      <OlogaPageHeader
        title="Add Station"
        items={[
          { label: "Events", url: "/events" },
          { label: "Stations", url: "/events/stations" },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StationForm
                station={{
                  id: "",
                  eventId: "",
                  eventName: "",
                  geoLocation: "",
                  name: "",
                  supervisorId: "",
                  supervisorName: "",
                  vendorId: "",
                  vendorName: "",
                  venueName: "",
                  submit: false,
                }}
                isVendor={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateVenue;
