import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Item from "./Item";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";

interface MultiSelectCheckboxProps {
  label: string;
  componentName: string;
  options: Item[];
  selectedOptions: Item[];
  onChange: (componentName: string, selectedOptions: Item[], e: any) => void;
}

const OlogaMultiInput: React.FC<MultiSelectCheckboxProps> = ({
  label,
  componentName,
  options,
  selectedOptions,
  onChange,
}) => {
  const tittleStyle = {
    marginLeft: "16px",
    marginTop: "3x",
  };
  const [selectedOptionsLocal, setSelectedOptionsLocal] = useState([]);

  const setTableFields = (e: any) => {
    const ops = (
      selectedOptionsLocal.length != 0
        ? selectedOptionsLocal
        : selectedOptions.length != 0
        ? selectedOptions
        : options
    ).map((item: any) =>
      "q-" + item.id === e.target.id
        ? { ...item, [e.target.name]: e.target.value, ["updated"]: 1 }
        : item
    );
    setSelectedOptionsLocal(ops as any);
    onChange(componentName, ops, e);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={tittleStyle}>
        {label}
      </FormLabel>
      <FormGroup>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {(selectedOptionsLocal.length != 0
                  ? selectedOptionsLocal
                  : selectedOptions.length != 0
                  ? selectedOptions
                  : options
                ).map(({ id, description, quantity }) => (
                  <TableCell key={id}>
                    <TextField
                      id={"q-" + id}
                      name="quantity"
                      value={quantity || ""}
                      onChange={setTableFields}
                      label={description + " - Quantity"}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </FormGroup>
    </FormControl>
  );
};

export default OlogaMultiInput;
