import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Typography,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import CancelButton from "../../components/buttonsx/CancelButton";
import OlogaGenericCombo from "../../components/combosx/OlogaGenericCombo";
import Item from "../../components/combosx/Item";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useAuth from "../../hooks/useAuth";
import { Description, VisibilityOutlined } from "@mui/icons-material";
import { size } from "polished";
import useLookups from "../../hooks/useLookups";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaDownloadExcel from "../../components/misc/OlogaDownloadExcel";
import { GridActionsCellItem } from "@mui/x-data-grid";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const EventProductImport: React.FC<any> = ({
  mode,
  url,
  successMessage,
  onSucessMessage,
}) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fromEvents, setFromEvents] = useState([]);
  const [targetEvents, setTargetEvents] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [totalCreated, setTotalCreated] = useState(0);
  const [totalCreatedBefore, setTotalCreatedBefore] = useState(0);
  const [totalUpdated, setTotalUpdated] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [vendorId, setVendorId] = useState(0);
  const [eventId, setEventId] = useState(0);
  const [totalAvaliable, setTotalAvaliavle] = useState(0);
  const { state } = useLocation();
  const { user: userContext } = useAuth();
  const { data: vendors } = useLookups("/companies/vendors/user");
  const [eventProductBatch, setEventProductBatch] = useState({
    targetEvent: null,
    vendor: null,
    imageUri: "",
  });

  const navigate = useNavigate();
  const params = useParams();

  const retrieveAllProducts = async () => {
    const response = await axios.get(`/externalProvider/iqretail/products`);
    setAllProducts(
      response.data.map((r: any) => ({
        productId: r.productId,
        productDescription: r.productDescription,
        price: r.price,
        cost: r.cost,
        quantity: r.quantity,
        imageUri: r.imageUri,
        sizes: r.sizes,
      }))
    );
  };

  const retrieveImportStats = async (vendorId: number, eventId: number) => {
    const response = await axios.get(
      `/eventProduct/iqretail/importstats/${vendorId}/${eventId}`
    );
    setTotalCreatedBefore(response.data.totalCreatedBefore);
    setTotalFailed(response.data.totalFailed);
    setTotalUpdated(0);
    setTotalAvaliavle(0);
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(eventProductBatch, "vendor", e);
    setVendorId(e.id);
    getVendorEvents(e.id.toString());
  };

  const handleChangeTargetEvent = (e: Item) => {
    updateVariable(eventProductBatch, "targetEvent", e);
    retrieveImportStats(vendorId, e.id);
    setEventId(e.id);
  };

  const getVendorEvents = async (vendorId: string) => {
    setTargetEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/vendorx/${vendorId}`);
        setTargetEvents(response.data);
      } catch (error) {}
    }
  };

  const retrieveTargetEvents = async () => {
    const response = await axios.get(`/events/names`);
    setTargetEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: "targetEvent",
        };
      })
    );
  };

  const onSubmit = async (data: any) => {
    try {
      setShowLoading(true);
      const response = await axios.post(
        `/externalProvider/iqretail/products/download/${vendorId}/${eventId}`
      );

      setTotalCreated(response.data.totalCreated);
      setTotalUpdated(response.data.totalUpdated);
      setTotalFailed(response.data.totalFailed);
      setTotalAvaliavle(
        response.data.totalCreated + response.data.totalUpdated
      );

      setShowLoading(false);

      if (response.data.totalFailed == 0) {
        toast.success("Products imported sucessfully");
      } else {
        toast.error(
          "Some products were not created sucessfully. Try to import with vendor users"
        );
      }
    } catch (error) {
      toast.error((error as any).message);
    }
    setShowSuccess(true);
  };

  useEffect(() => {
    const vendorId = eventProductBatch?.vendor
      ? (eventProductBatch?.vendor as any)?.id
      : userContext?.vendorId;
    const eventId = (eventProductBatch?.targetEvent as any)?.id;
    setVendorId(vendorId);
    setEventId(eventId);

    if (userContext?.roleName != "ROLE_ADMIN") {
      getVendorEvents(vendorId);
    }

    if (state && state.eventId && state.vendorId) {
      retrieveImportStats(state.vendorId, state.eventId);
      getVendorEvents(state.vendorId);
      setVendorId(state.vendorId);
      setEventId(state.eventId);
    }

    retrieveTargetEvents();
    retrieveAllProducts();
    setShowSuccess(false);
  }, [formMode]);

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setEventProductBatch({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setEventProductBatch({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Import Products"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <br />
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {userContext?.roleName == "ROLE_ADMIN" && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    label="Vendors"
                    name="vendor"
                    items={vendors}
                    value={eventProductBatch.vendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <OlogaGenericCombo
                  label="Target Event"
                  name="targetEvent"
                  items={targetEvents}
                  value={eventProductBatch.targetEvent || ""}
                  onChange={handleChangeTargetEvent}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>IQ Products to Import</TableCell>
                    <TableCell>Initial Products Imported to Pagamio</TableCell>
                    <TableCell>Additional Products Imported</TableCell>
                    <TableCell>Existent Products Updated</TableCell>
                    <TableCell>Total Products Imported</TableCell>
                    <TableCell>Products Failed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{allProducts.length}</TableCell>
                    <TableCell>{totalCreatedBefore}</TableCell>
                    <TableCell>{totalCreated}</TableCell>
                    <TableCell>{totalUpdated}</TableCell>
                    <TableCell>{totalAvaliable}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          navigate("/iqretail/importerrors", {
                            state: {
                              vendorId: vendorId,
                              eventId: eventId,
                              origin: "/iqretail/import",
                            },
                          });
                        }}
                      >
                        {totalFailed}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item marginLeft={2} className="form-buttons">
                <SubmitButton label={"Import"} onClick={onSubmit} />
              </Grid>
              <Grid item marginRight={2}>
                <CancelButton navigateTo={"../search"} />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventProductImport;
