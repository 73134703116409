import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useLookups from "../../hooks/useLookups";
import http from "../../utils/axios";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import { Box } from "@mui/material";
import OlogaSubmitButton from "../../components/buttonsx/OlogaSubmitButton";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import {
  companySchema,
  countryTourSchema,
  worldTourSchema,
} from "../../utils/formValidation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import useCountries from "../../hooks/useCountries";
import { formatDateToDdMmYyyy } from "../../utils/utils";

const Card = styled(MuiCard)(spacing);

interface WorldTourFormProps {
  tour?: any;
}

function CountryTourForm(props: any) {
  const navigate = useNavigate();
  const { countries } = useCountries();
  const { data: tours } = useLookups("/tours/world/names");
  const { closemodal } = props;
  const handleSubmit = async (
    values: any,
    { resetForm, setSubmitting }: any
  ) => {
    try {
      values.startDate = formatDateToDdMmYyyy(values.startDate);
      values.endDate = formatDateToDdMmYyyy(values.endDate);

      const url = "/tours/country";

      if (props?.tour?.id) {
        await http.put(url, values);
      } else {
        await http.post(url, values);
      }

      resetForm();
      setSubmitting(false);
      toast.success("Tour saved successfully!");
      if (!props.tour?.fromModel) {
        navigate("/events/country-tours");
      }
      if (props.tour?.fromModel) {
        closemodal();
      }
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error.message || "Error saving vendor");
    }
  };

  return (
    <Formik
      initialValues={props?.tour}
      validationSchema={countryTourSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <OlogaTextfield name="name" label="Name" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <OlogaSelect
                    name="worldTourId"
                    options={tours}
                    label="Word Tour"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <OlogaSelect
                    name="countryId"
                    options={countries}
                    label="Country"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <OlogaDatePicker
                    name="startDate"
                    label="Start Date"
                    disablePast={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <OlogaDatePicker
                    name="endDate"
                    label="End Date"
                    disablePast={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <OlogaTextfield
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              {props.tour.fromModel ? (
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <OlogaSubmitButton />
                </Box>
              ) : (
                <OlogaButtonGroup />
              )}
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default CountryTourForm;
