import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Card as MuiCard, Grid } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import { OlogaButton } from "../../components/buttonsx/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import useLookups from "../../hooks/useLookups";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

function Users() {
  const { user: userContext, isAdmin } = useAuth();
  const { data: vendors } = useLookups("/companies/vendors/names");
  const [url, setUrl] = useState("/users/q");
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      editable: false,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      editable: false,
    },
    {
      field: "textPassword",
      headerName: "Password",
      flex: 1,
      editable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/users/edit-user", {
              state: {
                user: params.row,
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      let q = "";
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      setUrl(q.length > 0 ? "/users/q?" + q.slice(0, -1) : "/users/q?");
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  useEffect(() => {}, [url]);

  return (
    <>
      <OlogaPageHeader
        title="Users"
        buttonLabel="New User"
        onClick={() => {
          navigate("/users/add-user");
        }}
      />

      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield name="firstName" label="First Name" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield name="lastName" label="Last Name" />
                  </Grid>

                  {isAdmin && (
                    <Grid item xs={12} md={3}>
                      <OlogaSelect
                        name="vendorId"
                        label="Vendor"
                        options={vendors}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Users;
