import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { SpacingProps, spacing } from "@mui/system";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useLookups from "../../hooks/useLookups";

import http from "../../utils/axios";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { merchantAllocationSchema } from "../../utils/formValidation";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface MerchantAllocationProps {
  showBreadcrumb?: boolean;
  isVendor?: boolean;
  vendorId?: any;
  eventId?: any;
}

function MerchantAllocation({
  showBreadcrumb = true,
  isVendor = false,
  vendorId = "",
  eventId = "",
}: MerchantAllocationProps) {
  const { data: vendors } = useLookups("/companies/vendors/names");
  const { data: merchants } = useLookups(
    isVendor ? `/users/merchants/vendor/${vendorId}` : "/users/merchants"
  );
  const [selectedVendorId, setSelectedVendorId] = useState(
    isVendor ? vendorId : ""
  );
  const [selectedEventId, setSelectedEventId] = useState(
    isVendor ? eventId : ""
  );
  const [vendorEvents, setVendorEvents] = useState([]);
  const [stations, setStations] = useState([]);
  const [url, setUrl] = useState("");
  const [selectedAllocation, setSelectedAllocation] = useState({
    vendorId: vendorId,
    eventId: eventId,
    merchantId: "",
    stationId: "",
    firstName: "",
    lastName: "",
    username: "",
    theMmerchant: "",
    stationAllocationId: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [toDelete, setToDeleted] = useState<any>({});
  const [inputValue, setInputValue] = useState("");

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: false,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      editable: false,
    },
    {
      field: "stationName",
      headerName: "Station",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setSelectedAllocation({
              vendorId: selectedVendorId,
              eventId: selectedEventId,
              merchantId: params.row.id,
              stationId: params.row.stationId,
              theMmerchant: "",
              firstName: params.row.firstName,
              lastName: params.row.lastName,
              username: params.row.username,
              stationAllocationId: "",
            });
            setInputValue(
              `${params.row.firstName} ${params.row.lastName} | ${params.row.username}`
            );
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            setToDeleted({ ...params.row });
            setOpenDialog(true);
          }}
          label="Delete"
        />,
      ],
    },
  ];

  const handleVendorChange = async (value: any) => {
    setSelectedVendorId(value);
    try {
      const response = await http.get(
        isVendor
          ? `/events/vendor/${value}/names`
          : `/events/upcoming/vendor/${value}/names`
      );
      setVendorEvents(response.data);
    } catch (error) {}
  };

  const handleEventChange = async (value: any) => {
    setUrl("");
    setSelectedEventId(value);
    try {
      const stationsResponse = await http.get(
        `/stations/event/${value}/vendor/${selectedVendorId}/names`
      );
      setStations(stationsResponse.data);
    } catch (error) {}
    setUrl(`/stations/allocations/event/${value}/vendor/${selectedVendorId}`);
  };

  const handleAutocompleteChange = (e: any, value: any) => {
    if (value) {
      setInputValue(
        value?.firstName + " " + value?.lastName + " | " + value?.username
      );
    } else {
      setInputValue("");
    }
  };

  const removeMerchant = async () => {
    if (toDelete?.id) {
      try {
        setUrl("");
        const result = await http.post(
          `/participations/remove-merchant-station`,
          toDelete
        );
        setOpenDialog(false);
        toast.success("Merchant removed successfully!");
        setUrl(
          `/stations/allocations/event/${selectedEventId}/vendor/${selectedVendorId}`
        );
      } catch (error: any) {
        setOpenDialog(false);
        toast.error(error.message || "Error removing merchant");
      }
    }
  };

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting, setFieldValue }: any
  ) => {
    try {
      setUrl("");
      if (values.stationAllocationId) {
        const response = await http.put(
          "/participations/merchant-station",
          values
        );
      } else {
        const response = await http.post(
          "/participations/merchant-station",
          values
        );
      }
      resetForm();
      setSelectedAllocation({
        vendorId: selectedVendorId,
        eventId: selectedEventId,
        merchantId: "",
        stationId: "",
        theMmerchant: "",
        firstName: "",
        lastName: "",
        username: "",
        stationAllocationId: "",
      });
      setFieldValue("merchantId", "", true);
      setInputValue("");
      setSubmitting(false);
      toast.success("Merchant allocated successfully!");
      setUrl(
        `/stations/allocations/event/${selectedEventId}/vendor/${selectedVendorId}`
      );
    } catch (error: any) {
      toast.error(error.message || "Error saving merchant");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (isVendor) {
      handleVendorChange(vendorId);
      handleEventChange(eventId);
    }
  }, [selectedVendorId]);

  return (
    <>
      {showBreadcrumb && (
        <OlogaPageHeader
          title="Merchant allocation"
          items={[{ label: "Events", url: "/events" }]}
        />
      )}

      <Formik
        initialValues={selectedAllocation}
        validationSchema={merchantAllocationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          touched,
          status,
          setFieldValue,
          values,
        }) => (
          <Card>
            <CardContent>
              <OlogaConfirmDialog
                title="Remove Merchant"
                description={`Remove ${toDelete.firstName} ${toDelete.lastName} from the event?`}
                open={openDialog}
                setOpen={setOpenDialog}
                handleConfirm={removeMerchant}
              />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <OlogaSelect
                      name="vendorId"
                      options={vendors}
                      label="Vendor"
                      setValue={handleVendorChange}
                      disabled={isVendor}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OlogaSelect
                      name="eventId"
                      options={vendorEvents}
                      label="Event"
                      setValue={handleEventChange}
                      disabled={isVendor}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="merchantId"
                      options={merchants}
                      inputValue={inputValue || ""}
                      getOptionLabel={(option: any) =>
                        option.firstName +
                        " " +
                        option.lastName +
                        " | " +
                        option.username
                      }
                      onChange={(e, value: any) => {
                        if (value) {
                          setInputValue(
                            value?.firstName +
                              " " +
                              value?.lastName +
                              " | " +
                              value?.username
                          );
                        } else {
                          setInputValue("");
                        }
                        setFieldValue(
                          "merchantId",
                          value !== null ? value?.id : "",
                          true
                        );
                      }}
                      onInputChange={(e, value: any) => {
                        if (e && e.type === "change") {
                          setInputValue(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="merchantId"
                          label="Merchant"
                          error={Boolean(
                            touched.merchantId && errors.merchantId
                          )}
                          fullWidth
                          helperText={touched.merchantId && errors.merchantId}
                          onBlur={handleBlur}
                          variant="outlined"
                          my={2}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OlogaSelect
                      name="stationId"
                      options={stations}
                      label="Station"
                    />
                  </Grid>
                </Grid>
                <OlogaButtonGroup />
                <Typography mt={6} variant="h6" gutterBottom>
                  Allocated Merchants
                </Typography>
                <OlogaDataGrid
                  url={url}
                  columns={columns}
                  isPagedContent={false}
                />
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </>
  );
}

export default MerchantAllocation;
