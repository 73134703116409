import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Grid,
  Modal,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import http from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/combosx/OlogaGenericCombo";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import Item from "../../components/combosx/Item";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { toast } from "react-toastify";
import OlogaSubmitButton from "../../components/buttonsx/OlogaSubmitButton";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import SyncIcon from "@mui/icons-material/Sync";
import useLookups from "../../hooks/useLookups";
import OlogaSelect from "../../components/select/OlogaSelect";
import { commissionSchema } from "../../utils/formValidation";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import { OlogaButton } from "../../components/buttonsx/OlogaButton";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function CommissionPage() {
  const { data: events } = useLookups("/events/names");
  const { data: vendors } = useLookups("/companies/vendors/names");
  const [selectedCommission, setSelectedCommission] = useState({
    id: "",
    eventId: "",
    eventName: "",
    vendorId: "",
    vendorName: "",
    rate: 0,
  });
  const [event, setEvent] = useState();
  const [items, setItems] = useState([]);
  // const [events, setEvents] = useState([{ id: 0, name: "" }]);

  const [uri, setUri] = useState("/commission/q?");
  const [eventId, setEventId] = useState(0);
  const [eventName, setEventName] = useState("");
  const [mode, setMode] = useState("");
  const [tax, setTax] = useState("");
  const [show, setShow] = useState(false);
  const [showBtnComm, setShowBtnComm] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedCommission({
      id: "",
      eventId: "",
      eventName: "",
      vendorId: "",
      vendorName: "",
      rate: 0,
    });
    setOpen(false);
  };
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "eventName",
      headerName: "Event",
      flex: 1.5,
      editable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1.5,
      editable: false,
    },
    {
      field: "rate",
      headerName: "Rate",
      flex: 0.5,
      editable: false,
    },
    // {
    //   field: "totalSales",
    //   headerName: "Total Sales",
    //   flex: 0.5,
    //   editable: false,
    // },
    // {
    //   field: "amount",
    //   headerName: "Commission",
    //   flex: 0.5,
    //   editable: false,
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        // <GridActionsCellItem
        //   icon={<SyncIcon />}
        //   onClick={() => {
        //     console.log(params);
        //     console.log("DTO", { id: params.id, ...params.row });
        //     refreshComm({ id: params.id, ...params.row });
        //   }}
        //   label="Detail"
        // />,
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            // const foundEvent = events.find((event) => event.id === params.id);
            // setEvent(foundEvent as any);
            // setTax(params.row.tax);
            setSelectedCommission(params.row);
            setMode("UPDATE");
            handleOpen();
          }}
          label="Detail"
        />,
      ],
    },
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #bb86fc",
    boxShadow: 24,
    p: 4,
  };

  const refreshComm = async (values: any) => {
    try {
      const response = await http.put("/commission/refresh", {
        ...values,
      });
      reload();
    } catch (error: any) {
      toast.error(
        "Some Error Occur while refresh Commission : " + error.message
      );
    }
  };
  const updateComm = async (values: any) => {
    try {
      const response = await http.put("/commission", {
        ...values,
      });
      reload();
    } catch (error: any) {
      toast.error(
        "Some Error Occur while refresh Commission : " + error.message
      );
    }
  };
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      setUri("");
      if (values.id) {
        const response = await http.put("/commission", {
          ...values,
        });
      } else {
        const response = await http.post("/commission", {
          ...values,
        });
      }
      toast.success("Commission saved Succefully");
      setSubmitting(false);
      handleClose();
      setUri("/commission/q?");
    } catch (error: any) {
      toast.error(error.message);
      setSubmitting(false);
    }
  };

  function reload(): void {
    return window.location.reload();
  }
  function CommissionModal() {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Commission
            </Typography>
            <br />
            <Formik
              initialValues={selectedCommission}
              validationSchema={commissionSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field name="eventId">
                        {({ field, form }: FieldProps) => (
                          <OlogaAutocomplete
                            {...field}
                            label="Event"
                            options={events}
                            initValue={selectedCommission?.eventName}
                            disabled={selectedCommission.id ? true : false}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="vendorId">
                        {({ field, form }: FieldProps) => (
                          <OlogaAutocomplete
                            {...field}
                            label="Vendor"
                            options={vendors}
                            initValue={selectedCommission?.vendorName}
                            disabled={selectedCommission.id ? true : false}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="rate">
                        {({ field }: FieldProps) => (
                          <OlogaTextfield {...field} label="Rate" />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <OlogaButtonGroup>
                    <OlogaButton
                      label="Cancel"
                      variant="outlined"
                      onClick={handleClose}
                      mr={2}
                    />
                    <OlogaButton
                      type="submit"
                      label="Save"
                      disabled={isSubmitting}
                    />
                  </OlogaButtonGroup>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>
    );
  }

  const handleChange = (event: Item) => {
    setEvent(event as any);
    setEventId(Number(event.id));
    setEventName(event.description);
  };

  const getData = async (url: string) => {
    try {
      setShow(false);
      const response = await http.get(url);
      console.log("RESPONSE", response);
      setItems(response.data.content);
      setShow(true);
      setShowBtnComm(true);
    } catch (error) {}
  };

  // const retriveEvents = async () => {
  //   const response = await http.get(`/events/names`);
  //   setEvents(
  //     response.data.map((r: Item) => {
  //       return {
  //         id: r.id,
  //         description: r.name,
  //         type: r.type,
  //         name: "event",
  //       };
  //     })
  //   );
  // };

  useEffect(() => {
    // retriveEvents();
  }, []);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Commissions"
        buttonLabel={"New Commission"}
        onClick={handleOpen}
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Paper>
              <OlogaDataGrid url={"/commission/q"} columns={columns} />
            </Paper>
          </Card>
        </Grid>
      </Grid>
      {/* </form> */}
      <div>
        <CommissionModal />
      </div>
    </React.Fragment>
  );
}

export default CommissionPage;
