import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import EventForm from "./EventForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

const Card = styled(MuiCard)(spacing);

function CreateEventPage() {
  return (
    <>
      <OlogaPageHeader
        title="Add Event"
        items={[{ label: "Events", url: "/events" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <EventForm
                event={{
                  id: "",
                  name: "",
                  description: "",
                  tourId: "",
                  countryId: "",
                  promoterId: "",
                  venueId: "",
                  startDate: "",
                  startTime: "",
                  endDate: "",
                  endTime: "",
                  expectedAudience: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateEventPage;
