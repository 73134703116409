import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import CompanyForm from "./CompanyForm";

const Card = styled(MuiCard)(spacing);

function CreateVendor() {
  return (
    <>
      <OlogaPageHeader
        title="Add Vendor"
        items={[{ label: "Vendors", url: "/events/vendors" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <CompanyForm
                type="Vendor"
                company={{
                  id: null,
                  name: "",
                  description: "",
                  countryId: "",
                  cityId: "",
                  address: "",
                  email: "",
                  phoneNumber: "",
                  representativeName: "",
                  representativePhoneNumber: "",
                  representativeEmail: "",
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateVendor;
