import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation } from "react-router-dom";
import CompanyForm from "./CompanyForm";

const Card = styled(MuiCard)(spacing);

function UpdateVendor() {
  const { state } = useLocation();
  return (
    <>
      <OlogaPageHeader
        title="Edit Vendor"
        items={[{ label: "Vendors", url: "/events/vendors" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <CompanyForm
                type="Vendor"
                company={{
                  ...state.company,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateVendor;
