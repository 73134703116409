import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { GridColDef } from "@mui/x-data-grid";

const ValueAddedServicesProducts = (props: any) => {
  const [open, setOpen] = useState(false);
  const [newEndpoint, setNewEndpoint] = useState({
    apiEndpoint: "",
    apiKey: "",
    apiUsername: "",
    apiPassword: "",
    authenticationType: "",
    environment: "",
    active: true,
    meta: "{}",
  });
  const handleAddEndpoint = () => {
    // setEndpoints([...endpoints, { ...newEndpoint, id: endpoints.length + 1 }]);
    setOpen(false);
    setNewEndpoint({
      apiEndpoint: "",
      apiKey: "",
      apiUsername: "",
      apiPassword: "",
      authenticationType: "",
      environment: "",
      active: true,
      meta: "{}",
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewEndpoint({ ...newEndpoint, [name]: value });
  };

  const columns: GridColDef[] = [
    {
      field: "imageUrl",
      headerName: "Image",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <img
          src={params.row.imageUrl}
          width="30px"
          height="auto"
          alt="product-img"
        />,
      ],
    },
    {
      field: "productCode",
      headerName: "Product code",
      flex: 0.5,
      editable: false,
    },
    {
      field: "productName",
      headerName: "Product name",
      flex: 1.5,
      editable: false,
    },
    {
      field: "isFixedAmount",
      headerName: "Fixed amount",
      flex: 0.5,
      editable: false,
    },
    {
      field: "minAmount",
      headerName: "Min amount",
      flex: 0.5,
      editable: false,
    },
    {
      field: "maxAmount",
      headerName: "Max amount",
      flex: 0.5,
      editable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      editable: false,
    },
    {
      field: "apiPath",
      headerName: "API path",
      flex: 1.5,
      editable: false,
    },
  ];
  // <TableCell>Category</TableCell>
  //               <TableCell>Product name</TableCell>
  //               <TableCell>Fixed Amount</TableCell>
  //               <TableCell>Min amount</TableCell>
  //               <TableCell>Max amount</TableCell>
  //               <TableCell>Vendor</TableCell>
  //               <TableCell>API Path</TableCell>
  //               <TableCell>Status</TableCell>
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid item style={{ marginBottom: "8px" }}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                style={{ marginTop: "1rem" }}
              >
                Add VAS Product
              </Button>
            </Grid>
          </Grid>

          <OlogaDataGrid
            url={`/vas/products/provider/${props.providerId}`}
            isPagedContent={false}
            paginationMode={"client"}
            columns={columns}
            exportToExcel={false}
            fileName="Events"
            excludeFromExport={["actions"]}
          />
          {/* <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Product name</TableCell>
                <TableCell>Fixed Amount</TableCell>
                <TableCell>Min amount</TableCell>
                <TableCell>Max amount</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>API Path</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {endpoints.map((endpoint: any) => (
                <TableRow key={endpoint.id}>
                  <TableCell>{endpoint.apiEndpoint}</TableCell>
                  <TableCell>{endpoint.apiKey}</TableCell>
                  <TableCell>{endpoint.apiUsername}</TableCell>
                  <TableCell>{endpoint.apiPassword}</TableCell>
                  <TableCell>{endpoint.authenticationType}</TableCell>
                  <TableCell>{endpoint.environment}</TableCell>
                  <TableCell>{endpoint.environment}</TableCell>
                  <TableCell>{endpoint.active ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add New Product</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="apiEndpoint"
                label="Category"
                fullWidth
                value={newEndpoint.apiEndpoint}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="apiKey"
                label="Product name"
                fullWidth
                value={newEndpoint.apiKey}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="apiUsername"
                label="Fixed Amount"
                fullWidth
                value={newEndpoint.apiUsername}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="apiPassword"
                label="API Password"
                fullWidth
                value={newEndpoint.apiPassword}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="authenticationType"
                label="Min Amount"
                fullWidth
                value={newEndpoint.authenticationType}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="environment"
                label="Max Amount"
                fullWidth
                value={newEndpoint.environment}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="environment"
                label="API Path"
                fullWidth
                value={newEndpoint.environment}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="active"
                label="Status"
                fullWidth
                value={newEndpoint.active}
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddEndpoint} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ValueAddedServicesProducts;
