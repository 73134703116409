import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../../utils/axios";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";

const IntegrationProviderEndpoints = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [endpoints, setEndpoints] = useState([]);
  const [open, setOpen] = useState(false);
  const [newEndpoint, setNewEndpoint] = useState({
    apiEndpoint: "",
    apiKey: "",
    apiUsername: "",
    apiPassword: "",
    authenticationType: "",
    environment: "",
    active: true,
    meta: "{}",
  });
  const handleAddEndpoint = () => {
    // setEndpoints([...endpoints, { ...newEndpoint, id: endpoints.length + 1 }]);
    setOpen(false);
    setNewEndpoint({
      apiEndpoint: "",
      apiKey: "",
      apiUsername: "",
      apiPassword: "",
      authenticationType: "",
      environment: "",
      active: true,
      meta: "{}",
    });
  };

  const columns: GridColDef[] = [
    {
      field: "apiEndPoint",
      headerName: "API Endpoint",
      flex: 1.5,
      editable: false,
    },
    {
      field: "apiKey",
      headerName: "API Key",
      flex: 1,
      editable: false,
    },
    {
      field: "apiUsername",
      headerName: "API Username",
      flex: 1,
      editable: false,
    },
    {
      field: "apiPassword",
      headerName: "API Password",
      flex: 1,
      editable: false,
    },
    {
      field: "authenticationType",
      headerName: "Authentication Type",
      flex: 1,
      editable: false,
    },
    {
      field: "environment",
      headerName: "Environment",
      flex: 1,
      editable: false,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      editable: false,
    },
    {
      field: "meta",
      headerName: "Meta",
      flex: 1.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {}}
          label="View"
        />,
      ],
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewEndpoint({ ...newEndpoint, [name]: value });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid item style={{ marginBottom: "8px" }}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                style={{ marginTop: "1rem" }}
              >
                Add Endpoint
              </Button>
            </Grid>
          </Grid>
          <Card>
            <Fade in={isLoading} unmountOnExit>
              <CircularProgress />
            </Fade>
            {!isLoading && (
              <OlogaDataGrid
                url={`/integration/providers/${props.providerId}/endpoints`}
                isPagedContent={false}
                paginationMode={"client"}
                columns={columns}
                exportToExcel={false}
                fileName="Events"
                excludeFromExport={["actions"]}
              />
            )}
          </Card>
        </Grid>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Add New Endpoint</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="apiEndpoint"
              label="API Endpoint"
              fullWidth
              value={newEndpoint.apiEndpoint}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="apiKey"
              label="API Key"
              fullWidth
              value={newEndpoint.apiKey}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="apiUsername"
              label="API Username"
              fullWidth
              value={newEndpoint.apiUsername}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="apiPassword"
              label="API Password"
              fullWidth
              value={newEndpoint.apiPassword}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="authenticationType"
              label="Authentication Type"
              fullWidth
              value={newEndpoint.authenticationType}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="environment"
              label="Environment"
              fullWidth
              value={newEndpoint.environment}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="active"
              label="Active"
              fullWidth
              value={newEndpoint.active}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="meta"
              label="Meta"
              fullWidth
              value={newEndpoint.meta}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddEndpoint} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default IntegrationProviderEndpoints;
