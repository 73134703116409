import { Box } from "@mui/material";
import OlogaCancelButton from "./OlogaCancelButton";
import OlogaSubmitButton from "./OlogaSubmitButton";

interface OlogaButtonGroupProps {
  cancelRoute?: string;
  children?: React.ReactNode;
}

function OlogaButtonGroup({ cancelRoute, children }: OlogaButtonGroupProps) {
  return (
    <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
      {children ? (
        children
      ) : (
        <>
          <OlogaCancelButton route={cancelRoute} />
          <OlogaSubmitButton />
        </>
      )}
    </Box>
  );
}

export default OlogaButtonGroup;
