import { useEffect } from "react";
import { useState } from "react";
import http from "../utils/axios";

const useVendors = () => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get("/companies/vendors/names");
        setVendors(result.data);
      } catch (error) {}
    };

    fetchData();
  }, []);
  return { vendors };
};

export default useVendors;
