import React, { useState } from "react";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  TextField,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import useLookups from "../../hooks/useLookups";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const IntegrationProvidersPage = () => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("/integration/providers");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: providerTypes } = useLookups(
    "/simple-entity/integration-provider-types"
  );
  const [newProvider, setNewProvider] = useState({
    code: "",
    name: "",
    type: "",
  });
  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Code",
      flex: 1.5,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      editable: true,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1.5,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("../details", {
              state: {
                provider: {
                  ...params.row,
                },
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const validationSchema = Yup.object({
    code: Yup.string().required("Code is required"),
    name: Yup.string().required("Name is required"),
    typeId: Yup.string().required("Type is required"),
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewProvider({ ...newProvider, [name]: value });
  };

  const handleAddProvider = (values: any) => {
    setOpen(false);
    console.log("Form submitted");
    console.log(values);
    setNewProvider({ code: "", name: "", type: "" });
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Integration Providers"
        buttonLabel={"New Provider"}
        onClick={() => {
          setOpen(true);
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Fade in={loading} unmountOnExit>
              <CircularProgress />
            </Fade>
            {!loading && (
              <OlogaDataGrid
                url={url}
                isPagedContent={false}
                paginationMode={"client"}
                columns={columns}
                exportToExcel={false}
                fileName="Events"
                excludeFromExport={["actions"]}
              />
            )}
          </Card>
        </Grid>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Add New Provider</DialogTitle>
          <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={handleAddProvider}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, status }) => (
              <form onSubmit={handleAddProvider}>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="code"
                    label="Code"
                    fullWidth
                    value={newProvider.code}
                    onChange={handleChange}
                  />
                  <TextField
                    margin="dense"
                    name="name"
                    label="Name"
                    fullWidth
                    value={newProvider.name}
                    onChange={handleChange}
                  />
                  <OlogaAutocomplete
                    name="typeId"
                    label="Type"
                    options={providerTypes}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleAddProvider} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default IntegrationProvidersPage;
