import styled from "@emotion/styled";

import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation } from "react-router-dom";
import CountryTourForm from "./CountryTourForm";

const Card = styled(MuiCard)(spacing);

function UpdateCountryTour() {
  const { state } = useLocation();
  return (
    <>
      <OlogaPageHeader
        title="Edit Country Tour"
        items={[{ label: "Country Tours", url: "/events/country-tours" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <CountryTourForm
                tour={{
                  ...state.tour,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateCountryTour;
