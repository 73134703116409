import Resizer from "react-image-file-resizer";

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

const ImageCompressor = async (file: Blob, name: String) => {
  const fileData = await resizeFile(file);
  const formData = new FormData();
  formData.append("file", fileData as Blob, name as string);
  return formData;
};

export default ImageCompressor;
